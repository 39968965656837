import { template as template_d3b91b7c76c24a109d5d0f942029c511 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d3b91b7c76c24a109d5d0f942029c511(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
