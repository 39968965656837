import { template as template_3cfa43f1e3d647209b118ad454717559 } from "@ember/template-compiler";
const FKText = template_3cfa43f1e3d647209b118ad454717559(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
