import { template as template_07ac3ef61b14443dbb4a88412364732b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_07ac3ef61b14443dbb4a88412364732b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
